import { AppManifest, EditorSDK, PageSettings } from '@wix/platform-editor-sdk';

import { buildPanelUrl } from '../../../../services/panel-url-builder';

type PageSettingsOptions = NonNullable<AppManifest['pages']>['pageSettings'];

const permissionsSettings: PageSettings = {
  title: 'Permissions',
  type: 'permissions',
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
};

const layoutSettings: PageSettings = {
  title: 'Layout',
  type: 'layout',
};

const getPaneInfoAction = async (editorSDK: EditorSDK): Promise<PageSettings> => ({
  title: 'Page Info',
  // @ts-expect-error
  event: 'pageInfo',
  icon: 'page_dynamic_icon',
  url: await buildPanelUrl(editorSDK, 'pageInfoV2.html'),
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
  type: 'page_info',
});

export const getPageSettings = async (editorSDK: EditorSDK): Promise<PageSettingsOptions> => {
  return {
    default: [await getPaneInfoAction(editorSDK), layoutSettings, permissionsSettings],
  };
};
